import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';

import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { ModalHostComponent, SharedFeaturesModalModule } from '@bp/frontend/features/modal';
import { SharedComponentsCoreModule } from '@bp/frontend/components/core';
import { MomentToNowPipe } from '@bp/frontend/features/moment/pipes';

@Component({
	selector: 'bp-subscription-fee-is-overdue-modal-page',
	standalone: true,
	templateUrl: './subscription-fee-is-overdue-modal-page.component.html',
	styleUrls: [ './subscription-fee-is-overdue-modal-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		NgIf,
		AsyncPipe,
		MatLegacyDialogModule,
		MatLegacyButtonModule,

		MomentToNowPipe,

		SharedFeaturesModalModule,
		SharedComponentsCoreModule,
	],
})
export class SubscriptionFeeIsOverdueModalPageComponent extends ModalHostComponent {

	protected readonly _currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	override panelClass = [ 'subscription-fee-is-overdue-modal-page-overlay-pane', 'modal-overlay-pane-big' ];

}
