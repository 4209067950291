import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseSendResetPasswordLinkFormComponent } from './base-send-reset-password-link-form.component';

@Component({
	selector: 'bp-send-reset-password-link-form',
	templateUrl: './send-reset-password-link-form.component.html',
	styleUrls: [ './send-reset-password-link-form.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendResetPasswordLinkFormComponent extends BaseSendResetPasswordLinkFormComponent {}
