import { combineLatest, distinctUntilChanged, filter, map, merge, pairwise, skip, switchMap } from 'rxjs';
import { take } from 'lodash-es';

import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { MODAL_OUTLET } from '@bp/shared/models/core';
import { Undefinable } from '@bp/shared/typings';

import { AnalyticsService, GoogleTagService, PardotService } from '@bp/frontend/features/analytics';
import { filterPresent, takeFirstPresent } from '@bp/frontend/rxjs';
import { IGoogleTagGlobalVariables } from '@bp/frontend/features/analytics/models';
import { CurrentCrmOrganizationFacade } from '@bp/frontend/domains/crm/organizations/+current-crm-organization-state';
import { CurrentCrmUserFacade } from '@bp/frontend/domains/crm/users/+current-crm-user-state';
import {
	UserIdleService, CloudflareAccessService, SupportAgentDetectorService
} from '@bp/frontend/services/core';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { IntercomService } from '@bp/frontend/services/intercom';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { CookiesUsageConsentService } from '@bp/frontend/features/analytics/cookies-usage-consent';
import { RouterService } from '@bp/frontend/services/router';
import { MerchantAdminDesignState } from '@bp/frontend/services/persistent-state-keepers';

import { AdminSharedAppStartupService } from '@bp/admins-shared/core/services';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { Identity } from '@bp/admins-shared/domains/identity/models';
import { BridgerPspsSharedFacade } from '@bp/admins-shared/domains/bridger-psps';
import { SubscriptionPlansSharedFacade } from '@bp/admins-shared/domains/subscription-plans';

import { MerchantPspsFacade } from '@bp/merchant-admin-shared/frontend/domains/merchant-psps';
import { CurrentOrganizationFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization';
import {
	CurrentOrganizationSubscriptionFacade
} from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';
import {
	CurrentMemberFacade, AccessibleOrganizationsFacade
} from '@bp/merchant-admin-shared/frontend/domains/current-member';
import { CheckoutsFacade } from '@bp/merchant-admin-shared/frontend/domains/checkouts';
import {
	PURCHASE_SUBSCRIPTION_PLAN_ROUTE_PATH, SUBSCRIPTION_FEE_IS_OVERDUE_ROUTE_PATH,
	SUBSCRIPTION_SUSPENDED_ROUTE_PATH
} from '@bp/merchant-admin-shared/frontend/domains/routing';

@Injectable({ providedIn: 'root' })
export class AppStartupService {

	private readonly __isSupportAgent = inject(SupportAgentDetectorService).isSupportAgent;

	private readonly __adminSharedAppStartup = inject(AdminSharedAppStartupService);

	private readonly __identityFacade = inject(IdentityFacade);

	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	private readonly __currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	private readonly __currentCrmOrganizationFacade = inject(CurrentCrmOrganizationFacade);

	private readonly __currentCrmUserFacade = inject(CurrentCrmUserFacade);

	private readonly __bridgerPspsSharedFacade = inject(BridgerPspsSharedFacade);

	private readonly __currentMemberFacade = inject(CurrentMemberFacade);

	private readonly __merchantPspsFacade = inject(MerchantPspsFacade);

	private readonly __accessibleOrganizationsFacade = inject(AccessibleOrganizationsFacade);

	private readonly __checkoutsFacade = inject(CheckoutsFacade);

	private readonly __telemetry = inject(TelemetryService);

	private readonly __userIdleService = inject(UserIdleService);

	private readonly __intercomService = inject(IntercomService);

	private readonly __pardotService = inject(PardotService);

	private readonly __subscriptionPlansSharedFacade = inject(SubscriptionPlansSharedFacade);

	private readonly __googleTagService = inject(GoogleTagService);

	private readonly __analyticsService = inject(AnalyticsService);

	private readonly __environment = inject(EnvironmentService);

	private readonly __cloudflareAccessService = inject(CloudflareAccessService);

	private readonly __cookiesUsageConsentService = inject(CookiesUsageConsentService);

	private readonly __router = inject(Router);

	private readonly __routerService = inject(RouterService);

	init(): void {
		this.__adminSharedAppStartup.init();

		this.__userIdleService.init();

		this.__pardotService.init();

		if (this.__environment.isStagingOrPreview)
			this.__cloudflareAccessService.whenUserUnauthorizedByCloudflareRedirectToCloudflareLoginPage();

		this.__identityFacade.onIdentityFirstSet(identity => {
			this.__currentOrganizationFacade.load();

			this.__currentOrganizationFacade.startRefreshingInInterval();

			this.__bridgerPspsSharedFacade.loadAll();

			this.__accessibleOrganizationsFacade.loadAll();

			this.__subscriptionPlansSharedFacade.loadAll();

			this.__currentOrganizationSubscriptionFacade.load();

			if (!this.__isSupportAgent) {
				this.__currentMemberFacade.track();

				this.__associateUserWithThirdParties(identity);

				this.__initCrmLogic(identity);
			}
		});

		this.__identityFacade.onPermissionFirstPresence(
			MerchantAdminFeature.checkouts,
			() => void this.__checkoutsFacade.loadAll(),
		);

		this.__identityFacade.onPermissionFirstPresence(
			MerchantAdminFeature.psps,
			() => void this.__merchantPspsFacade.loadAll(),
		);

		this.__identityFacade.onIdentityLogout(() => {
			void this.__intercomService.reboot();

			this.__currentOrganizationFacade.stopRefreshingInInterval();

			this.__currentOrganizationFacade.resetState();

			this.__currentOrganizationSubscriptionFacade.resetState();

			this.__accessibleOrganizationsFacade.resetState();

			this.__merchantPspsFacade.resetState();

			this.__checkoutsFacade.resetState();

			this.__bridgerPspsSharedFacade.resetState();

			this.__subscriptionPlansSharedFacade.resetState();

			this.__currentCrmOrganizationFacade.resetState();

			this.__currentCrmUserFacade.resetState();
		});

		this.__whenUserLoggedInDispatchLoginGoogleTagEvent();

		this.__whenMerchantRelatedInfoUpdatedUpdateTelemetry();

		this.__whenSubscriptionFeeIsOverdueShowWarningModalPage();

		this.__whenSubscriptionSuspendedRedirectToDedicatedPage();

		this.__whenNewFreePlanKeepUserAtSubscriptionPlansPage();

		this.__whenIdentityChangesSetGoogleTagVariables();

		this.__whenMerchantPspsUpdatedPushToCRM();

		this.__whenCheckoutsUpdatedPushToCRM();

		this.__intercomService.init();

		this.__initAnalyticsKindServicesOnCookiesUsageConsent();
	}

	private __initAnalyticsKindServicesOnCookiesUsageConsent(): void {
		this.__cookiesUsageConsentService.consent$
			.pipe(takeFirstPresent)
			.subscribe(consent => void this.__analyticsService.init(consent));

		this.__cookiesUsageConsentService.consent$
			.pipe(
				filterPresent,
				skip(1), // skip the first emission, because it's handled by init
			)
			.subscribe(consent => void this.__analyticsService.updateConsent(consent));
	}

	private __initCrmLogic(identity: Identity): void {
		this.__currentCrmUserFacade.observeCrmUserRemoteChanges(identity.id!);

		this.__updateCrmUserBasedOnIdentity(identity);

		if (identity.organizationId)
			this.__currentCrmOrganizationFacade.observeCrmOrganizationRemoteChanges(identity.organizationId);
	}

	private __whenIdentityChangesSetGoogleTagVariables(): void {
		merge(this.__identityFacade.incompleteIdentity$.pipe(filterPresent), this.__identityFacade.user$).subscribe(
			identity => {
				if (identity)
					this.__updateCrmUserBasedOnIdentity(identity);
				else
					this.__currentCrmUserFacade.resetState();

				void this.__setIdentityGoogleTagVariables({
					// eslint-disable-next-line @typescript-eslint/naming-convention
					user_id: identity?.id ?? undefined,
					userEmail: identity?.email,
					merchantId: identity?.organizationId ?? undefined,
					usesNewDesign: MerchantAdminDesignState.isV3Mode,
				});
			},
		);
	}

	private __associateUserWithThirdParties(user: Identity): void {
		this.__telemetry.identifyUser({
			id: user.id!,
			email: user.email,
			userRoles: user.roles,
		});

		this.__currentMemberFacade.memberHash$
			.pipe(takeFirstPresent)
			.subscribe(hash => void this.__intercomService.update({
				userId: user.id!,
				userHash: hash,
				name: user.name ?? undefined,
				email: user.email,
			}));
	}

	private __whenMerchantRelatedInfoUpdatedUpdateTelemetry(): void {
		this.__identityFacade.userPresent$
			.pipe(
				distinctUntilChanged((a, b) => a.organizationId === b.organizationId),
				switchMap(() => combineLatest([
					this.__currentOrganizationFacade.presentEntity$,
					this.__currentOrganizationSubscriptionFacade.entity$,
				])),
			)
			.subscribe(([ currentOrganization, currentSubscription ]) => {
				const companyTelemetry = {
					merchantId: currentOrganization.id ?? undefined,
					// website: currentOrganization.company.website, TODO take from crm organization
					plan: currentSubscription?.subscriptionPlan.type.displayName ?? 'none',
					monthlySpend: currentSubscription?.monthPrice,
				};

				void this.__intercomService.company({
					...companyTelemetry,
					id: currentOrganization.id ?? undefined,
					name: currentOrganization.name ?? undefined,
				});

				const usersCompanyTelemetry = {
					...companyTelemetry,
					companyName: currentOrganization.name ?? undefined,
				};

				this.__telemetry.setTags(usersCompanyTelemetry);

				void this.__currentCrmOrganizationFacade.updateAndSaveOrganizationKeptInStore({
					id: currentOrganization.id,
					subscription: currentSubscription,
					companyName: currentOrganization.name!,
					updatedBy: this.__identityFacade.user?.email,
					ownerId: this.__identityFacade.user?.isOwner ? this.__identityFacade.user.id! : undefined,
					ownerEmail: this.__identityFacade.user?.isOwner ? this.__identityFacade.user.email : undefined,
				});
			});
	}

	private __setIdentityGoogleTagVariables(
		variables: Undefinable<Required<Pick<IGoogleTagGlobalVariables, 'merchantId' | 'user_id' | 'userEmail' | 'usesNewDesign'>>>,
	): void {
		this.__googleTagService.setGlobalVariables(variables);
	}

	private __whenUserLoggedInDispatchLoginGoogleTagEvent(): void {
		this.__identityFacade.userHasLoggedIn$.subscribe(() => void this.__googleTagService.dispatchEvent('login'));
	}

	private __whenMerchantPspsUpdatedPushToCRM(): void {
		this.__merchantPspsFacade.allEnabled$
			.pipe(filter(() => !!this.__identityFacade.user))
			.subscribe(
				psps => void this.__currentCrmOrganizationFacade.updateAndSaveOrganizationKeptInStore({
					psps: psps
						? take(psps, 80).map(psp => ({ name: psp.pspName, type: psp.type.name }))
						: undefined,
				}),
			);
	}

	private __whenCheckoutsUpdatedPushToCRM(): void {
		this.__checkoutsFacade.all$
			.pipe(filter(() => !!this.__identityFacade.user))
			.subscribe(
				checkouts => void this.__currentCrmOrganizationFacade.updateAndSaveOrganizationKeptInStore({
					checkouts: checkouts ?? undefined,
				}),
			);
	}

	private __updateCrmUserBasedOnIdentity(identity: Identity): void {
		void this.__currentCrmUserFacade.updateAndSaveUserKeptInStore({
			id: identity.id,
			email: identity.email,
			fullName: identity.name ?? undefined,
			roles: identity.roles ?? undefined,
			organizationId: identity.organizationId!,
			isOwner: identity.isOwner,
		});
	}

	private __whenSubscriptionSuspendedRedirectToDedicatedPage(): void {
		this.__currentCrmOrganizationFacade.organization$
			.pipe(
				map(crmOrganization => !!crmOrganization?.status?.isSuspended),
				distinctUntilChanged(),
				pairwise(),
			)
			.subscribe(([ previousIsSuspended, currentIsSuspended ]) => {
				if (currentIsSuspended)
					void this.__router.navigateByUrl(`/${ SUBSCRIPTION_SUSPENDED_ROUTE_PATH }`);

				if (!!previousIsSuspended && !currentIsSuspended)
					void this.__router.navigateByUrl('/');
			});
	}

	private __whenSubscriptionFeeIsOverdueShowWarningModalPage(): void {
		this.__currentCrmOrganizationFacade.organizationPresent$
			.pipe(
				map(crmOrganization => !!crmOrganization.status?.isWarning),
				distinctUntilChanged(),
				pairwise(),
			)
			.subscribe(([ previousIsFeeOverdue, currentIsFeeOverdue ]) => {
				if (currentIsFeeOverdue) {
					void this.__router.navigate([
						{
							outlets: {
								modal: [ SUBSCRIPTION_FEE_IS_OVERDUE_ROUTE_PATH ],
							},
						},
					]);
				}

				if (!!previousIsFeeOverdue && !currentIsFeeOverdue)
					void this.__routerService.closeOutlet(MODAL_OUTLET);
			});
	}

	private __whenNewFreePlanKeepUserAtSubscriptionPlansPage(): void {
		this.__currentOrganizationSubscriptionFacade.isMustPurchaseSubscriptionPlan$
			.pipe(
				filter(
					isMustPurchaseSubscriptionPlan => isMustPurchaseSubscriptionPlan
						&& !this.__currentCrmOrganizationFacade.organization?.status?.isSuspended,
				),
			)
			.subscribe(
				() => void this.__router.navigate([ PURCHASE_SUBSCRIPTION_PLAN_ROUTE_PATH ]),
			);
	}

}
