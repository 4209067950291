@if (nextSyncTimestamp) {
	@if (nextSyncTimestamp | momentIsSameOrBeforeNow | async) {
		<span> Data is synching... </span>
	} @else {
		@if (lastSyncTimestamp) {
			<ng-container *ngTemplateOutlet="lastSyncWasAtTpl; context: { hasCommaAtTheEnd: true }" />
			<ng-container *ngTemplateOutlet="nextSyncInTpl" />
		} @else {
			<ng-container *ngTemplateOutlet="nextSyncInTpl; context: { singleOnPage: true }" />
		}
	}
} @else {
	<ng-container *ngTemplateOutlet="lastSyncWasAtTpl" />
}

<ng-template #lastSyncWasAtTpl let-hasCommaAtTheEnd="hasCommaAtTheEnd">
	@if (lastSyncTimestamp) {
		Last data sync was
		<span
			class="link interactive use-current-color"
			bpTooltip="{{ lastSyncTimestamp | momentFormat: 'LLL' | async }}"
		>
			{{ lastSyncTimestamp | momentFromNow | async }}{{ hasCommaAtTheEnd ? ',' : '' }}
		</span>
	}
</ng-template>

<ng-template #nextSyncInTpl let-singleOnPage="singleOnPage">
	@if (singleOnPage) {
		Next data sync
	} @else {
		next sync
	}

	<span class="link interactive use-current-color" bpTooltip="{{ nextSyncTimestamp | momentFormat: 'LLL' | async }}">
		{{ nextSyncTimestamp | momentToNow | async }}
	</span>
</ng-template>
