import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { SharedComponentsCoreModule } from '@bp/frontend/components/core';

import {
	MerchantAdminSharedFrontendDomainsIdentityModule
} from '@bp/merchant-admin-shared/frontend/domains/identity';

@Component({
	selector: 'bp-subscription-suspended-page',
	standalone: true,
	templateUrl: './subscription-suspended-page.component.html',
	styleUrls: [ './subscription-suspended-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		MatIconModule,
		MerchantAdminSharedFrontendDomainsIdentityModule,
		SharedComponentsCoreModule,
	],
})
export class SubscriptionSuspendedPageComponent {
}
