import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';

import { AnnouncementsFacade } from '../../state';

@Component({
	selector: 'bp-announcements-hub',
	templateUrl: './announcements-hub.component.html',
	styleUrls: [ './announcements-hub.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class AnnouncementsHubComponent {
	constructor(protected readonly _announcementsFacade: AnnouncementsFacade) {}
}
