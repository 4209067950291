import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	DTO, MapFromDTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams,
	mixinMetadataSortQueryParams, Validator
} from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';

import { Member } from './member';
import { MemberRole } from './member-role.enum';

type MembersQueryParamsDTO = DTO<MembersQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO;

export class MembersQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, Member),
) {
	@Mapper(Number)
	@Validator(Validators.digits)
	override page?: string | undefined;

	@Mapper(MemberRole)
	role?: MemberRole | null;

	@MapFromDTO()
	name?: string;

	constructor(dto?: MembersQueryParamsDTO) {
		super(dto);
	}

}
