import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseSendResetPasswordLinkPageComponent } from './base-send-reset-password-link-page.component';

@Component({
	selector: 'bp-send-reset-password-link-page-v3',
	templateUrl: './send-reset-password-link-page.component.html',
	styleUrls: [ './send-reset-password-link-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendResetPasswordLinkPageComponent extends BaseSendResetPasswordLinkPageComponent {
}
