import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { ApiMockPluginsManagerService } from '@bp/frontend/services/core';

import { CheckoutsEffects } from './state/list/checkouts.effects';
import { checkoutsFeature } from './state';

@NgModule({
	imports: [ StoreModule.forFeature(checkoutsFeature), EffectsModule.forFeature([ CheckoutsEffects ]) ],
})
export class MerchantAdminSharedFrontendDomainsCheckoutsRootModule {

	constructor(private readonly _apiMocksManagerService: ApiMockPluginsManagerService) {
		void this._apiMocksManagerService.register(
			MerchantAdminSharedFrontendDomainsCheckoutsRootModule,
			[
				async () => import('./api-mock-plugin/checkouts-api-mock-plugin'),
				async () => import('./api-mock-plugin/checkouts-nest-api-mock-plugin'),
				async () => import('./api-mock-plugin/checkout-activation-api-mock-plugin'),
			],
		);
	}

}
