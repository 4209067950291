import { combineLatest, from, map, startWith, switchMap, asyncScheduler } from 'rxjs';

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatAutocompleteDefaultOptions, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { AsyncPipe, NgIf } from '@angular/common';

import { SharedComponentsControlsModule } from '@bp/frontend/components/controls';

import { CurrentOrganizationFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization';
import { AccessibleOrganizationsFacade } from '@bp/merchant-admin-shared/frontend/domains/current-member';

@Component({
	selector: 'bp-login-to-organization',
	standalone: true,
	imports: [
		AsyncPipe,
		NgIf,
		SharedComponentsControlsModule,
	],
	templateUrl: './login-to-organization.component.html',
	styleUrls: [ './login-to-organization.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
			useValue: {
				overlayPanelClass: 'organizations-autocomplete-overlay-panel',
			} satisfies MatAutocompleteDefaultOptions,
		},
	],
})
export class LoginToOrganizationComponent {

	protected readonly _accessibleOrganizationsFacade = inject(AccessibleOrganizationsFacade);

	protected readonly _currentOrganizationFacade = inject(CurrentOrganizationFacade);

	protected readonly _currentOrganization$ = combineLatest([
		this._accessibleOrganizationsFacade.all$,
		this._currentOrganizationFacade.entity$,
	]).pipe(

		map(([ organizations, currentOrganization ]) => organizations
			.find(organization => organization.id === currentOrganization?.id)),
		switchMap(organization => this._accessibleOrganizationsFacade.onDismissConfirmConnectToOrganization$.pipe(
			// null to reset angular on push object reference check
			switchMap(() => from([ null, organization ], asyncScheduler)),
			startWith(organization),
		)),
	);
}
