import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

import { SLIDE } from '@bp/frontend/animations';
import { FORM_FIELD_DEFAULT_OPTIONS, IFormFieldDefaultOptions } from '@bp/frontend/components/core';

import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-intro-shell-page',
	templateUrl: './intro-shell-page.component.html',
	styleUrls: [ './intro-shell-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		SLIDE,
	],
	providers: [
		{
			provide: FORM_FIELD_DEFAULT_OPTIONS,

			useValue: <IFormFieldDefaultOptions>{
				appearance: 'round-lg',
				hideRequiredMarker: true,
			},
		},
	],
})
export class IntroShellPageComponent {

	@HostBinding('class.intro-shell-page')
	introShellPage = true;

	introPageSteps = IntroPageStep.getList();

	constructor(public introFacade: IntroFacade) { }

}
