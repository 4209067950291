import { EMPTY, exhaustMap, map, switchMap, tap, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';

import { SECONDS_IN_MINUTE } from '@bp/shared/utilities/core';
import { SupportRequestStatus } from '@bp/shared/domains/support-requests';

import { apiNullableResult, apiVoidResult } from '@bp/frontend/models/common';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';

import { CurrentOrganizationFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization';
import { SupportRequestCreateModel } from '@bp/merchant-admin-shared/shared/domains/support-requests';
import { CurrentOrganizationSubscriptionFacade } from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';

import { RequestSupportDialogComponent } from '../../components';
import { RequestSupportApiService } from '../../services';

import {
	close, getActiveSupportRequest, requestSupport, open, completeSupportRequest,
	startSupportRequestCheckTimer, changeSupportRequestDuration, stopSupportRequestCheckTimer
} from './request-support.actions';
import {
	getActiveSupportRequestSuccess, getActiveSupportRequestFailure, requestSupportSuccess,
	completeSupportRequestSuccess, changeSupportRequestDurationSuccess, updateSupportRequestFailure,
	requestSupportFailure
} from './request-support-api.actions';
import { RequestSupportFacade } from './request-support.facade';

@Injectable()
export class RequestSupportEffects {
	private __requestSupportDialog: MatDialogRef<RequestSupportDialogComponent> | null = null;

	private readonly __actions$ = inject(Actions);

	private readonly __dialog = inject(MatDialog);

	private readonly __toastrService = inject(ToastrService);

	private readonly __requestSupportApiService = inject(RequestSupportApiService);

	private readonly __requestSupportFacade = inject(RequestSupportFacade);

	private readonly __currentOrganizationFacade = inject(CurrentOrganizationFacade);

	private readonly __currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	private readonly __identityFacade = inject(IdentityFacade);

	startSupportRequestCheckTimer$ = createEffect(() => this.__actions$.pipe(
		ofType(startSupportRequestCheckTimer, stopSupportRequestCheckTimer),
		switchMap(({ type }) => type === startSupportRequestCheckTimer.type
			? timer(0, SECONDS_IN_MINUTE * 5 * 1000).pipe(map(() => getActiveSupportRequest()))
			: EMPTY),
	));

	stopSupportRequestCheckTimerOnLogout$ = createEffect(() => this.__identityFacade.userHasLoggedOut$
		.pipe(map(stopSupportRequestCheckTimer)));

	onGetActiveSupportRequest$ = createEffect(() => this.__actions$.pipe(
		ofType(getActiveSupportRequest),
		switchMap(() => this.__requestSupportApiService
			.getActiveSupportRequest()
			.pipe(apiNullableResult(getActiveSupportRequestSuccess, getActiveSupportRequestFailure))),
	));

	onDialogOpen$ = createEffect(() => this.__actions$.pipe(
		ofType(open),
		exhaustMap(() => {
			this.__requestSupportDialog = this.__dialog.open(RequestSupportDialogComponent);

			return this.__requestSupportDialog.afterClosed();
		}),
		tap(() => (this.__requestSupportDialog = null)),
		map(close),
	));

	onRequestSupport$ = createEffect(() => this.__actions$.pipe(
		ofType(requestSupport),
		concatLatestFrom(() => [
			this.__currentOrganizationFacade.presentEntity$,
			this.__currentOrganizationSubscriptionFacade.presentEntity$,
		]),
		exhaustMap(([{ supportRequestCreateModel }, organization, currentOrganizationSubscription ]) => this.__requestSupportApiService
			.requestSupport(
				new SupportRequestCreateModel({
					...supportRequestCreateModel,
					organizationName: organization.name!,
					organizationSubscriptionPlan: currentOrganizationSubscription.subscriptionPlan.displayName,
				}),
			)
			.pipe(apiVoidResult(requestSupportSuccess, requestSupportFailure))),
	));

	onRequestSupportSuccess$ = createEffect(
		() => this.__actions$.pipe(
			ofType(requestSupportSuccess),
			tap(() => {
				this.__toastrService.success('Support was successfully invited');

				this.__requestSupportDialog?.close();
			}),
		),
		{ dispatch: false },
	);

	onChangeSupportRequestDuration$ = createEffect(() => this.__actions$.pipe(
		ofType(changeSupportRequestDuration),
		concatLatestFrom(() => this.__requestSupportFacade.presentEntity$),
		exhaustMap(([{ supportRequestDurationChange }, entity ]) => this.__requestSupportApiService
			.updateSupportRequest(entity.id!, {
				expiresAt: entity.expiresAt.add(supportRequestDurationChange.seconds, 'second'),
			})
			.pipe(apiNullableResult(changeSupportRequestDurationSuccess, updateSupportRequestFailure))),
	));

	onChangeSupportRequestDurationSuccess$ = createEffect(
		() => this.__actions$.pipe(
			ofType(changeSupportRequestDurationSuccess),
			tap(() => this.__toastrService.success('Support request duration was updated')),
		),
		{ dispatch: false },
	);

	onRevokeSupportAccess$ = createEffect(() => this.__actions$.pipe(
		ofType(completeSupportRequest),
		concatLatestFrom(() => this.__requestSupportFacade.presentEntity$),
		exhaustMap(([ , entity ]) => this.__requestSupportApiService
			.updateSupportRequest(entity.id!, { status: SupportRequestStatus.supportRequestCompleted })
			.pipe(apiVoidResult(completeSupportRequestSuccess, updateSupportRequestFailure))),
	));

	onRevokeSupportAccessSuccess$ = createEffect(
		() => this.__actions$.pipe(
			ofType(completeSupportRequestSuccess),
			tap(() => {
				this.__toastrService.success('Support access was successfully revoked');

				this.__requestSupportDialog?.close();
			}),
		),
		{ dispatch: false },
	);

	restartActiveSupportCheckTimer$ = createEffect(() => this.__actions$.pipe(
		ofType(open, requestSupportSuccess, requestSupportFailure, updateSupportRequestFailure),
		map(() => startSupportRequestCheckTimer()),
	));
}
