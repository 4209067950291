import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { Validators } from '@bp/shared/features/validation/models';
import { ensureFormGroupConfig } from '@bp/shared/typings';

import { FormBaseComponent } from '@bp/frontend/components/core';
import { IGoogleIdentity } from '@bp/frontend/features/oauth';

import { ILoginApiRequest } from '@bp/admins-shared/domains/identity/models';

@Directive()
export abstract class BaseLoginFormComponent extends FormBaseComponent<ILoginApiRequest> {

	@Input() showOAuthProviders = true;

	@Output() readonly sendResetPasswordLink = new EventEmitter();

	@Output() readonly googleIdentityChange = new EventEmitter<IGoogleIdentity>();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(
			ensureFormGroupConfig<ILoginApiRequest>({
				userName: [ '', { validators: Validators.email() }],
				password: [ '' ],
			}),
		);
	}

}
