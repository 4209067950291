import { inject } from '@angular/core';

import { IntroFacade } from '@bp/admins-shared/pages/intro/core';
import { IdentityFacade } from '@bp/admins-shared/domains/identity';

export abstract class BaseOtpOnLoginPageComponent {

	protected readonly _introFacade = inject(IntroFacade);

	protected readonly _identityFacade = inject(IdentityFacade);

}
