import type m from 'moment';

import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';

import {
	MomentFormatPipe,
	MomentFromNowPipe, MomentIsSameOrBeforeNowPipe, MomentToNowPipe
} from '@bp/frontend/features/moment/pipes';
import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

@Component({
	selector: 'bp-data-sync-state',
	standalone: true,
	imports: [
		AsyncPipe,
		NgTemplateOutlet,

		MomentIsSameOrBeforeNowPipe,
		MomentToNowPipe,
		MomentFromNowPipe,
		MomentFormatPipe,
		SharedFeaturesTooltipModule,
	],
	templateUrl: './data-sync-state.component.html',
	styleUrls: [ './data-sync-state.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSyncStateComponent {

	@Input() lastSyncTimestamp?: m.MomentInput | null;

	@Input() nextSyncTimestamp?: m.MomentInput | null;

	@Input()
	@HostBinding('class.pending-stub')
	pending?: boolean | null;

}
