<bp-layout
	[class.subscription-suspended]="(_currentCrmOrganizationFacade.organization$ | async)?.status?.isSuspended"
	[class.support-mode]="_isSupportAgent"
	[class.sandbox-mode]="(_currentOrganizationFacade.entity$ | async)?.isSandbox"
	[@routeHitAnimations]="_shouldAnimateOutlet"
	(@routeHitAnimations.done)="_shouldAnimateOutlet = false"
>
	<bp-payment-card-brands-svg-icons-definitions />

	<bp-right-drawers-orchestrator />

	<bp-notifications-hub />

	<bp-announcements-hub />

	<bp-modal-outlet>
		<router-outlet name="modal" />
	</bp-modal-outlet>

	<bp-menu *ngIf="_isMainNavigationEnabled$ | async" />

	<bp-toolbar
		*ngIf="_identityFacade.userIsLoggedIn$ | async"
		[userName]="(_identityFacade.user$ | async)!.email"
		[hasSidenavMenu]="(_isMainNavigationEnabled$ | async) === true"
		(logout)="_identityFacade.confirmLogout()"
	>
		<div class="ng-content-toolbar-left-section">
			<bp-login-to-organization *ngIf="_currentOrganizationFacade.entity$ | async" @fade />
		</div>

		<div
			*ngIf="_currentOrganizationFacade.entity$ | async as organization"
			class="ng-content-toolbar-right-section flex"
		>
			<ng-container
				*ngIf="_currentOrganizationSubscriptionFacade.presentEntity$ | async as currentOrganizationSubscription"
			>
				<a
					*ngIf="currentOrganizationSubscription.isFree && !organization.wasCreatedAfterFreePlanDisabling"
					mat-flat-button
					type="button"
					color="accent"
					class="mr-3 align-self-center bp-round-button bp-button-md"
					@fadeIn
					[routerLink]="[{ outlets: { modal: ['subscription-management', { mode: 'upgrade' }] } }]"
				>
					<span class="inline-flex-align-items-center">
						<bp-svg-icon class="bp-icon-sm mr-1 ml-n1" name="sparkles" />
						Upgrade Plan
					</span>
				</a>

				<div *ngIf="!currentOrganizationSubscription.isFree" class="mr-3 flex-align-items-center">
					<bp-current-subscription-limits-meter />
				</div>
			</ng-container>

			<ng-container *ngIf="!(_currentOrganizationSubscriptionFacade.presentEntity$ | async)?.isFree">
				<ng-container *bpHasAccess="_MerchantAdminFeature.requestSupport">
					<bp-request-support-open-button class="mr-2" />
				</ng-container>
			</ng-container>

			<bp-announcements-hub-open-button class="mr-2" />

			<bp-notifications-hub-bell-btn class="mr-2" />
		</div>

		<div class="ng-content-toolbar-user-menu-items">
			<button
				*bpHasAccess="_MerchantAdminFeature.newDesign"
				mat-menu-item
				(click)="_MerchantAdminDesignState.reloadInV3Mode()"
			>
				Switch to new design
			</button>

			<a
				*bpHasAccess="_MerchantAdminFeature.changePassword"
				mat-menu-item
				[routerLink]="[{ outlets: { '[right-drawer]': ['identity', 'change-password'] } }]"
			>
				Change Password
			</a>

			<a mat-menu-item [routerLink]="[{ outlets: { '[right-drawer]': ['identity', 'preferences'] } }]">
				Preferences
			</a>

			<ng-container
				*ngIf="_currentOrganizationSubscriptionFacade.presentEntity$ | async as currentOrganizationSubscription"
			>
				<ng-container *bpHasAccess="_MerchantAdminFeature.subscriptionsManagement.actions.edit">
					<a
						*ngIf="!currentOrganizationSubscription.isEnterprise"
						mat-menu-item
						[routerLink]="[{ outlets: { modal: ['subscription-management'] } }]"
					>
						Manage Your Plan
					</a>
				</ng-container>

				<ng-container *bpHasAccess="_MerchantAdminFeature.billing">
					<a
						*ngIf="currentOrganizationSubscription.hasBillingPortal"
						mat-menu-item
						class="flex-align-items-center"
						(click)="_currentOrganizationSubscriptionFacade.openBillingPortal()"
					>
						Billing

						<mat-icon class="material-icons-round bp-icon-sm ml-auto mr-0"> open_in_new </mat-icon>
					</a>
				</ng-container>
			</ng-container>
		</div>
	</bp-toolbar>

	<router-outlet (activate)="_shouldAnimateOutlet = true" />

	<bp-admins-footer>
		<button type="button" class="interactive link mr-4 use-current-color" onClick="window.illow.showWidget()">
			Privacy control center
		</button>
	</bp-admins-footer>
</bp-layout>
