import { Directive, EventEmitter, Output } from '@angular/core';

import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';

import { ILoginOtpVerificationApiRequest } from '@bp/admins-shared/domains/identity/models';

@Directive()
export abstract class BaseOtpOnLoginFormComponent extends FormBaseComponent<ILoginOtpVerificationApiRequest> {

	@Output() readonly login = new EventEmitter<void>();

	@Output() readonly resetAuthApp = new EventEmitter<void>();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<ILoginOtpVerificationApiRequest>({
			code: [ null, { validators: Validators.required }],
		}));
	}

}
