import { NgModule } from '@angular/core';

import { AdminsSharedPagesIntroCoreModule } from '@bp/admins-shared/pages/intro/core';

import { AdminsSharedPagesIntroModule } from './admins-shared-pages-intro.module';

@NgModule({
	imports: [
		AdminsSharedPagesIntroModule,
		AdminsSharedPagesIntroCoreModule,
	],
})
export class AdminsSharedPagesIntroRootModule { }
