import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseOtpOnLoginPageComponent } from './base-otp-on-login-page.component';

@Component({
	selector: 'bp-otp-on-login-page',
	templateUrl: './otp-on-login-page.component.html',
	styleUrls: [ './otp-on-login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpOnLoginPageComponent extends BaseOtpOnLoginPageComponent {
}
