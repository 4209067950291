import '@bp/frontend/startup';
import { bootstrapApplication } from '@angular/platform-browser';
import { importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';

import { TelemetryService } from '@bp/frontend/services/telemetry';
import { MerchantAdminDesignState } from '@bp/frontend/services/persistent-state-keepers';

import { AppModule } from './app/app.module';
import { RootComponent } from './app/core';

/**
 * We bootstrap app in next event loop because we have microtasks `bpQueueMicrotask` we need to finish
 * executing before running main app
 */
setTimeout(async () => {
	const [ rootComponent, appModule ] = MerchantAdminDesignState.isV3Mode
		? await import('@bp/apps/merchant-admin-v3-frontend').then(m => <const>[ m.RootComponent, m.AppModule ])
		: [ RootComponent, AppModule ];

	void bootstrapApplication(rootComponent, {
		providers: [
			provideAnimations(),
			provideZoneChangeDetection({
				ignoreChangesOutsideZone: true,
				eventCoalescing: true,
				runCoalescing: true,
			}),
			importProvidersFrom(appModule),
		],
	})
		.catch(TelemetryService.appErrorHandler);
});
