import { createAction, props } from '@ngrx/store';

import { SupportRequest, SupportRequestDurationChange } from '@bp/shared/domains/support-requests';

import { SupportRequestCreateModel } from '@bp/merchant-admin-shared/shared/domains/support-requests';

import { ACTIONS_SCOPE } from './request-support-api.actions';

export const open = createAction(`${ ACTIONS_SCOPE } Open`);

export const close = createAction(`${ ACTIONS_SCOPE } Close`);

export const getActiveSupportRequest = createAction(
	`${ ACTIONS_SCOPE } Get Active Support Request`,
);

export const requestSupport = createAction(
	`${ ACTIONS_SCOPE } Request Support`,
	props<{ supportRequestCreateModel: SupportRequestCreateModel }>(),
);

export const completeSupportRequest = createAction(
	`${ ACTIONS_SCOPE } Complete Support Request`,
	props<{ supportRequest: SupportRequest }>(),
);

export const startSupportRequestCheckTimer = createAction(
	`${ ACTIONS_SCOPE } Start Support Request Check Timer`,
);

export const stopSupportRequestCheckTimer = createAction(
	`${ ACTIONS_SCOPE } Stop Support Request Check Timer`,
);

export const changeSupportRequestDuration = createAction(
	`${ ACTIONS_SCOPE } Change Support Request duration`,
	props<{ supportRequestDurationChange: SupportRequestDurationChange }>(),
);
