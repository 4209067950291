import { Directive, EventEmitter, Output } from '@angular/core';

import { ensureFormGroupConfig } from '@bp/shared/typings';
import { Validators } from '@bp/shared/features/validation/models';

import { FormBaseComponent } from '@bp/frontend/components/core';

import { ISendResetPasswordLinkApiRequest } from '@bp/admins-shared/domains/identity/models';

@Directive()
export abstract class BaseSendResetPasswordLinkFormComponent extends FormBaseComponent<ISendResetPasswordLinkApiRequest> {

	@Output() readonly login = new EventEmitter();

	override onSubmitShowInvalidInputsToast = false;

	constructor() {
		super();

		this.form = this._formBuilder.group(ensureFormGroupConfig<ISendResetPasswordLinkApiRequest>({
			userName: [ '', { validators: Validators.email() }],
		}));
	}

}
