import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BaseLoginPageComponent } from './base-login-page.component';

@Component({
	selector: 'bp-login-page',
	templateUrl: './login-page.component.html',
	styleUrls: [ './login-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent extends BaseLoginPageComponent {}
