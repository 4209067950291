import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import { FADE, FADE_IN } from '@bp/frontend/animations';

import {
	CurrentOrganizationSubscriptionFacade
} from '@bp/merchant-admin-shared/frontend/domains/current-organization-subscription';

@Component({
	selector: 'bp-current-subscription-limits-meter',
	templateUrl: './current-subscription-limits-meter.component.html',
	styleUrls: [ './current-subscription-limits-meter.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN, FADE ],
})
export class CurrentSubscriptionLimitsMeterComponent {
	readonly currentOrganizationSubscriptionFacade = inject(CurrentOrganizationSubscriptionFacade);

	isLimitsMeterPopupOpen = false;

	popupPosition: ConnectedPosition[] = [
		{
			originX: 'center',
			originY: 'bottom',
			overlayX: 'center',
			overlayY: 'top',
		},
	];
}
