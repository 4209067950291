import { sumBy } from 'lodash-es';

import type { OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SharedFeaturesTooltipModule } from '@bp/frontend/features/tooltip';

@Component({
	selector: 'bp-enabled-items-of-total-summary',
	standalone: true,
	imports: [
		SharedFeaturesTooltipModule,
	],
	templateUrl: './enabled-items-of-total-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnabledItemsOfTotalSummaryComponent implements OnChanges {
	@Input() items!: { isEnabled: boolean }[];

	enabled = 0;

	total = 0;

	ngOnChanges({ items }: Partial<SimpleChanges>): void {
		if (items) {
			this.enabled = sumBy(this.items, v => Number(v.isEnabled));

			this.total = this.items.length;
		}
	}
}
