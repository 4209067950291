import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { IntroEffects } from './+state/intro.effects';
import { FEATURE_KEY, reducer } from './+state/intro.reducer';

@NgModule({
	imports: [
		StoreModule.forFeature(FEATURE_KEY, reducer),
		EffectsModule.forFeature([ IntroEffects ]),
	],
})
export class AdminsSharedPagesIntroCoreModule {}
