import { ChangeDetectionStrategy, Component } from '@angular/core';

import { Destroyable } from '@bp/frontend/models/common';
import { FADE_IN } from '@bp/frontend/animations';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Component({
	selector: 'bp-reset-authenticator-app-verification-page',
	templateUrl: './reset-authenticator-app-verification-page.component.html',
	styleUrls: [ './reset-authenticator-app-verification-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ FADE_IN ],
})
export class ResetAuthenticatorAppVerificationPageComponent extends Destroyable {

	constructor(
		public introFacade: IntroFacade,
		public identityFacade: IdentityFacade,
	) {
		super();

		this.identityFacade.loadIdentitySecurityQuestions();
	}

}
