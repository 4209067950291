import { inject } from '@angular/core';

import { IdentityFacade } from '@bp/admins-shared/domains/identity';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

export abstract class BaseSendResetPasswordLinkPageComponent {

	protected readonly _introFacade = inject(IntroFacade);

	protected readonly _identityFacade = inject(IdentityFacade);

}
