import { IPageQueryParamsDTO, ISortQueryParamsDTO } from '@bp/shared/models/common';
import {
	DTO, MapFromDTO, Mapper, MetadataQueryParamsBase, mixinMetadataPageQueryParams,
	mixinMetadataSortQueryParams, Validator
} from '@bp/shared/models/metadata';
import { Validators } from '@bp/shared/features/validation/models';

import { SupportRequest } from './support-request';
import { SupportRequestStatus } from './support-request-status.enum';

type SupportRequestsQueryParamsDTO = DTO<SupportRequestsQueryParams>
& IPageQueryParamsDTO
& ISortQueryParamsDTO;

export class SupportRequestsQueryParams extends mixinMetadataPageQueryParams(
	mixinMetadataSortQueryParams(MetadataQueryParamsBase, SupportRequest),
) {

	@Mapper(SupportRequestStatus)
	status?: SupportRequestStatus;

	@Mapper(Number)
	@Validator(Validators.digits)
	override page?: string | undefined;

	@MapFromDTO()
	organizationName?: string;

	constructor(dto?: SupportRequestsQueryParamsDTO) {
		super(dto);
	}

}
