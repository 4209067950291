import { Component, ChangeDetectionStrategy, inject } from '@angular/core';

import {
	getBridgerAdminMockUsersCredentials, getMerchantAdminMockUsersCredentials, getNeverExpiringEpoch,
	getProMockUserCredentials, IdentityJwtSessionDTO, MockUserEmail, OtpProvider
} from '@bp/shared/domains/jwt-session';
import { BridgerAdminFeature, MerchantAdminFeature } from '@bp/shared/domains/permissions';
import { JwtToken } from '@bp/shared/utilities/core';

import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';
import { SLIDE, SLIDE_IN, SLIDE_UP } from '@bp/frontend/animations';
import { PLATFORM } from '@bp/frontend/services/environment';

import { IntroPageStep } from '@bp/admins-shared/pages/intro/models';
import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

interface IDevLink {

	title: string;

	url: string;

}

@Component({
	selector: 'bp-intro-shell-page-footer',
	templateUrl: './intro-shell-page-footer.component.html',
	styleUrls: [ './intro-shell-page-footer.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, SLIDE_IN, SLIDE_UP ],
})
export class IntroShellPageFooterComponent {

	MockedBackendState = MockedBackendState;

	mockUsersCredentials = Object.values([
		'merchant-admin', 'merchant-admin-v3',
	].includes(inject(PLATFORM))
		? getMerchantAdminMockUsersCredentials()
		: getBridgerAdminMockUsersCredentials());

	devLinks: IDevLink[] = [
		{
			title: 'Accept Invite',
			url: `intro/${ IntroPageStep.acceptInvite.kebabCase }?jwt=${ this._generateMockAuthJWT([
				BridgerAdminFeature.baAcceptInvite.claim,
				MerchantAdminFeature.acceptInvite.claim,
			]) }`,
		},
		{
			title: IntroPageStep.createAccount.titleDisplayName,
			url: `intro/${ IntroPageStep.createAccount.kebabCase }?jwt=${ this._generateMockAuthJWT([
				BridgerAdminFeature.baCreateAccount.claim,
				MerchantAdminFeature.createAccount.claim,
			]) }`,
		},

		{
			title: IntroPageStep.setSecurityQuestionsAnswers.titleDisplayName,
			url: `intro/${ IntroPageStep.setSecurityQuestionsAnswers.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.setSecurityQuestionsAnswers.claim,
			]) }`,
		},
		{
			title: IntroPageStep.registerAuthenticatorApp.titleDisplayName,
			url: `intro/${ IntroPageStep.registerAuthenticatorApp.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.registerAuthenticator.claim,
			]) }`,
		},
		{
			title: IntroPageStep.sendResetAuthenticatorAppLink.titleDisplayName,
			url: `intro/${ IntroPageStep.sendResetAuthenticatorAppLink.kebabCase }`,
		},
		{
			title: IntroPageStep.resetPassword.titleDisplayName,
			url: `intro/${ IntroPageStep.resetPassword.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.resetPassword.claim,
			]) }`,
		},
		{
			title: 'Reset Expired Password',
			url: `intro/${ IntroPageStep.resetPassword.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.setPasswordOnLogin.claim,
			]) }`,
		},
		{
			title: `${ IntroPageStep.resetPasswordVerification.titleDisplayName }`,
			url: `intro/${ IntroPageStep.resetPasswordVerification.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetPassword.claim,
				MerchantAdminFeature.otpResetPasswordVerification.claim,
			]) }`,
		},
		{
			title: `${ IntroPageStep.resetPasswordVerification.titleDisplayName } via only answers`,
			url: `intro/${ IntroPageStep.resetPasswordVerification.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetPassword.claim,
			]) }`,
		},
		{
			title: '2-step Reset Authenticator',
			url: `intro/${ IntroPageStep.resetAuthenticatorAppVerification.kebabCase }?jwt=${ this._generateMockAuthJWT([
				MerchantAdminFeature.verifyAnswerBeforeResetAuthenticator.claim,
			]) }`,
		},
		{
			title: 'Login via JWT',
			url: `intro/${ IntroPageStep.login.kebabCase }?jwt=${ JwtToken.encode(getProMockUserCredentials()) }`,
		},
	];

	constructor(public introFacade: IntroFacade) { }

	private _generateMockAuthJWT(permissions: string[], otpProvider?: OtpProvider): string {
		return JwtToken.encode<IdentityJwtSessionDTO>({
			userIdentityId: '5778191a-d31f-46e3-8313-0757cab97486',
			userFullName: 'Mock Auth Test',
			userEmail: MockUserEmail.Pro,
			otpProvider: otpProvider?.name,
			permissions,
			exp: getNeverExpiringEpoch(),
		});
	}
}
