import { Directive, inject } from '@angular/core';

import { MockUserEmail } from '@bp/shared/domains/jwt-session';

import { EnvironmentService } from '@bp/frontend/services/environment';
import { MockedBackendState } from '@bp/frontend/services/persistent-state-keepers';

import { IntroFacade } from '@bp/admins-shared/pages/intro/core';

@Directive()
export abstract class BaseLoginPageComponent {

	protected readonly _environment = inject(EnvironmentService);

	protected readonly _introFacade = inject(IntroFacade);

	constructor() {
		this._loginDemoUserInDemoMode();
	}

	protected _loginDemoUserInDemoMode(): void {
		if (MockedBackendState.isDemoMode)
			this._introFacade.loginAsMockBackendUser(MockUserEmail.Demo);
	}

}
