import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { Platform } from '@bp/shared/typings';

import { PLATFORM } from '@bp/frontend/services/environment';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { environment } from '@bp/frontend/environments';
import { LOCAL_SERVER_META_REDUCERS_PROVIDERS, REMOTE_SERVER_META_REDUCERS_PROVIDERS } from '@bp/frontend/state';

import { AdminsSharedPagesErrorsModule } from '@bp/admins-shared/pages/errors';
import { AdminsSharedPagesIntroRootModule } from '@bp/admins-shared/pages/intro';
import { CONTROL_DEFAULT_OPTIONS_PROVIDERS } from '@bp/admins-shared/core/models';

import {
	MerchantAdminSharedFrontendDomainsMerchantPspsRootModule
} from '@bp/merchant-admin-shared/frontend/domains/merchant-psps';
import { SharedMerchantAdminAppModule } from '@bp/merchant-admin-shared/frontend/domains/apps';
import {
	MerchantAdminSharedFrontendDomainsIdentityModule
} from '@bp/merchant-admin-shared/frontend/domains/identity';

import { AppRoutingModule } from './app-routing.module';

TelemetryService.log('App module execution begun');

@NgModule({
	imports: [
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.isDeployed,
			registrationStrategy: 'registerWithDelay:1000',
		}),
		AdminsSharedPagesIntroRootModule,
		MerchantAdminSharedFrontendDomainsMerchantPspsRootModule.forRoot({
			paymentRouteLinkFactory: paymentRoute => [
				'/payment-routes',
				{
					outlets: {
						'[right-drawer][1]': [ 'payment-route', { id: paymentRoute.id, drawerType: 'view' }],
					},
				},
			],
		}),
		MerchantAdminSharedFrontendDomainsIdentityModule.forRoot(),
		AppRoutingModule,
		SharedMerchantAdminAppModule,
		AdminsSharedPagesErrorsModule,
	],
	providers: [
		environment.isDeployed ? REMOTE_SERVER_META_REDUCERS_PROVIDERS : LOCAL_SERVER_META_REDUCERS_PROVIDERS,
		CONTROL_DEFAULT_OPTIONS_PROVIDERS,
		{
			provide: PLATFORM,
			useValue: <Platform>'merchant-admin',
		},
	],
})
export class AppModule {}
