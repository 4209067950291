import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'bp-home-page',
	standalone: true,
	templateUrl: './home-page.component.html',
	styleUrls: [ './home-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent {

}
